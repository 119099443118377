import React, { useContext } from 'react'
import mergeWith from 'lodash.mergewith'
import { Box } from 'theme-ui'
import { ModalContext } from '@solid-ui-components/Modal'
import { TabsContext } from '@solid-ui-components/Tabs'
import { buildLinkProps } from '@solid-ui-components/ContentButtons'

const linkStyles = {
  textDecoration: `none`
}

const ContentContainer = ({ content, children, ...props }) => {
  const { setActiveModal } = useContext(ModalContext)
  const { setActiveTab } = useContext(TabsContext)

  let mergedProps = props
  let linkProps = {}

  if (content) {
    const { bg, link, ...contentRest } = content
    mergedProps = mergeWith(
      {},
      props,
      { ...contentRest, sx: { bg, background: bg, ...linkStyles } },
      (a, b) => (b === null ? a : undefined)
    )
    // Linking container
    if (link) {
      linkProps = buildLinkProps({
        content: link,
        setActiveModal,
        setActiveTab
      })?.linkProps
    }
  }

  return (
    <Box {...mergedProps} {...linkProps}>
      {children}
    </Box>
  )
}

export default ContentContainer
