import { CSSProperties, Fragment } from 'react';
import ContentText from '@solid-ui-components/ContentText';
import { Box, Flex, Text } from 'theme-ui';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ContentContainer from '@solid-ui-components/ContentContainer';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import { nanoid } from 'nanoid';

const CustomContainer = styled(ContentContainer)`
  padding: 0;
  border-radius: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
`;
const ErrorDiv = styled.div`
  padding: 15px 20px;
  background: #f03a47;
  color: #fff;
  margin-bottom: 40px;
  width: 100%;
`;
type Header = {
  key: string;
  value: string;
  style?: CSSProperties;
  link?: string;
};

type ItemChild = {
  value: Array<string>;
  type?: string;
};
type Item = {
  key: string;
  value: Array<string>;
  style?: CSSProperties;
  link?: string;
  icon?: string;
  child?: Array<ItemChild>;
};

type Props = {
  id?: string;
  header?: Header;
  items: Array<Item>;
  itemClassName?: string;
  itemCommonCSS?: CSSProperties;
  outerStyle?: CSSProperties;
  outerClass?: string;
  errorMessage?: string;
};

const commonStyles = {
  listStyle: { margin: '0 2rem' },
  accordionBodyFont: { fontWeight: 400 },
  categoryHeader: {
    padding: '25px',
    marginBottom: 0,
    lineHeight: '1',
  },
  outerBox: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    border: '1px solid #ddd',
    overflow: 'hidden',
    alignSelf: 'flex-start',
    a: { textDecoration: 'none', color: '#444' },
  },
  innerBox: {
    borderBottom: '1px solid #ddd',
    backgroundColor: '#F0F0F0',
  },
  categoryCountText: {
    backgroundColor: '#6564FD',
    alignSelf: 'center',
    color: 'white',
    minWidth: '20px',
    textAlign: 'center',
    borderRadius: '4px',
  },
  accordion: {
    '.accordion__button': {
      cursor: `pointer`,
      position: `relative`,
      outline: `none`,
      transition: `all 0.15s ease-in`,
    },
    '.accordion__button:hover': {
      opacity: 0.75,
      '&::before': {
        borderColor: `beta`,
      },
    },
    '.accordion__button::before': {
      content: `' '`,
      width: `7px`,
      height: `7px`,
      marginRight: `12px`,
      borderBottom: `3px solid currentColor`,
      borderLeft: `3px solid currentColor`,
      position: `absolute`,
      right: 1,
      top: `50%`,
      transform: `translate(0, -50%) rotate(-45deg)`,
      transition: `all 0.15s ease-in`,
    },
    '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
      transform: `translate(0, -50%) rotate(135deg)`,
      transition: `transform 0.35s ease-in`,
    },
    '[hidden]': {
      display: 'none',
    },
    '.accordion__panel': {
      animation: `fadeIn 1s ease-out`,
      overflow: 'hidden',
    },
    '@keyframes fadeIn': {
      '0%': {
        maxHeight: 0,
        pb: 0,
      },
      '100%': {
        maxHeight: '100vh',
        pb: 0,
      },
    },
  },
};

const List = ({
  header,
  outerStyle,
  items,
  itemCommonCSS,
  id,
  outerClass,
  errorMessage,
}: Props) => {
  const renderHeader = header && (
    <ContentText
      key={nanoid()}
      sx={{
        ...commonStyles.categoryHeader,
        ...header.style,
      }}
      content={{ text: header.value }}
      variant='h5'
    />
  );
  const RenderItem = ({
    style,
    value,
    icon,
    child,
  }: {
    value: string | Array<string>;
    style?: CSSProperties;
    icon?: string;
    child?: Array<ItemChild>;
  }) => {
    const commonKey = nanoid();
    return (
      <CustomContainer
        key={`${commonKey}_customContainer`}
        variant='cards.primary'
        sx={{
          ...itemCommonCSS,
          ...style,
        }}
      >
        <AccordionItem key={`${commonKey}_accordionItem`}>
          <AccordionItemHeading key={`${commonKey}_accordionItemHeading`}>
            <AccordionItemButton
              key={`${commonKey}_accordionItemButton`}
              className={child ? 'link accordion__button' : 'link'}
              style={child && { color: '#444 !important' }}
            >
              <Flex
                key={`${commonKey}_flex`}
                sx={{ alignItems: `flex`, justifyContent: 'space-between' }}
                px='3'
                py='3'
              >
                {decodeText(value)}
                {!child && (
                  <Text
                    key={`${commonKey}_text`}
                    variant='span'
                    style={commonStyles.categoryCountText}
                  >
                    {icon}
                  </Text>
                )}
              </Flex>
            </AccordionItemButton>
          </AccordionItemHeading>
          {child && (
            <AccordionItemPanel key={`${commonKey}_AccordionItemPanel`}>
              {child?.map(({ value, type }, index) => (
                <Fragment>
                  {type === 'dot' ? (
                    <ul
                      style={commonStyles.listStyle}
                      key={`${commonKey}_ul_${index}`}
                    >
                      {value.map((item) => {
                        const itemKey = nanoid();
                        return (
                          <li key={`${commonKey}_ul_${index}-li-${itemKey}`}>
                            <ContentText
                              key={`${commonKey}_ul_${index}-li-${itemKey}_ContentText`}
                              sx={commonStyles.accordionBodyFont}
                              content={{ text: item }}
                              variant='p'
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ) : type === 'number' ? (
                    <ol
                      style={commonStyles.listStyle}
                      key={`${commonKey}_ol_${index}`}
                    >
                      {value.map((item) => {
                        const itemKey = nanoid();
                        return (
                          <li key={`${commonKey}_ul_${index}-li-${itemKey}`}>
                            <ContentText
                              key={`${commonKey}_ul_${index}-li-${itemKey}_ContentText`}
                              sx={commonStyles.accordionBodyFont}
                              content={{ text: item }}
                              variant='p'
                            />
                          </li>
                        );
                      })}
                    </ol>
                  ) : (
                    <ContentText
                      key={`${commonKey}_ContentText_${index}`}
                      sx={commonStyles.accordionBodyFont}
                      content={{ text: value?.join(' ') }}
                      variant='p'
                      pt='0'
                      pb='2'
                      px='4'
                    />
                  )}
                </Fragment>
              ))}
            </AccordionItemPanel>
          )}
        </AccordionItem>
      </CustomContainer>
    );
  };
  const generalKey = nanoid();
  return items?.length !== 0 ? (
    <Box
      key={generalKey}
      id={id}
      className={outerClass}
      sx={{
        ...commonStyles.outerBox,
        ...outerStyle,
      }}
    >
      {header && (
        <Box sx={commonStyles.innerBox} key={`${generalKey}_Box_1`}>
          {header.link ? (
            <Link to={header.link} key={`${generalKey}_Link_1`}>
              {renderHeader}
            </Link>
          ) : (
            renderHeader
          )}
        </Box>
      )}
      <Box sx={commonStyles.accordion} key={`${generalKey}_Box_2`}>
        <Accordion
          key={`${generalKey}_Accordion`}
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {items.map(({ key, link, ...itemProps }, index) =>
            link ? (
              <Link to={link} key={`${generalKey}_Link_2_${index}`}>
                <RenderItem
                  key={`${generalKey}_RenderItem_1_${index}`}
                  {...itemProps}
                />
              </Link>
            ) : (
              <RenderItem
                key={`${generalKey}_RenderItem_2_${index}`}
                {...itemProps}
              />
            )
          )}
        </Accordion>
      </Box>
    </Box>
  ) : (
    <ErrorDiv>{errorMessage || 'No results found.'}</ErrorDiv>
  );
};
const decodeText = (value) => {
  return typeof value === 'string' ? (
    <ContentText
      key={nanoid()}
      py='1'
      content={{
        text: typeof value === 'string' ? value : value[0],
      }}
      variant='p'
      mb='0'
    />
  ) : (
    <p css={{ margin: 0, padding: '0.25rem 0' }}>
      {value?.map?.((item) => {
        if (item.charAt(0) === '|') {
          return (
            <span css={{ backgroundColor: 'yellow' }}>{item.substring(1)}</span>
          );
        } else {
          return <span>{item}</span>;
        }
      })}
    </p>
  );
};
export default List;
