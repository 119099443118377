import { useState } from 'react';

type Props = {
  dataset: Object;
  prefix?: string[];
  exception?: string[];
};

const useSearch = ({ dataset, prefix, exception }: Props) => {
  const [newDataset, setNewDataset] = useState(null);
  const [value, setValue] = useState('');
  const toBeSearched = Object.keys(dataset).filter((item) =>
    ifContains({ item, prefix, exception })
  );
  function onSearch({ target: { value } }) {
    let newDetailedTexts: Array<any> = [];
    toBeSearched.map((item) => {
      dataset[item].description.detailedTexts.map((detailedText) => {
        const combinedValue = detailedText.value.join(' ');
        const newValue = checkIfContains(value, combinedValue);
        if (JSON.stringify(detailedText.value) !== JSON.stringify(newValue)) {
          newDetailedTexts.push({ ...detailedText, value: newValue });
        }
      });
    });
    setNewDataset(newDetailedTexts);
    setValue(value);
  }
  function reset(e) {
    setNewDataset(null);
    setValue('');
    return true;
  }
  return { onChange: onSearch, value, newDataset, reset };
};

//this function filters the dataset for inputs
const checkIfContains = (searchValue: string, text: string) => {
  const startIndex = text
    .toLocaleLowerCase()
    .indexOf(searchValue.toLocaleLowerCase());
  const lastIndex = startIndex !== -1 ? startIndex + searchValue.length : -1;
  const newValue = [
    text.substring(0, startIndex),
    text.substring(startIndex, lastIndex) !== ''
      ? '|' + text.substring(startIndex, lastIndex)
      : '',
    text.substring(lastIndex),
  ].filter((item) => item !== '');
  return newValue;
};

//this function filters the dataset for categories
const ifContains = ({
  item,
  prefix,
  exception,
}: {
  item: string;
  prefix?: string[];
  exception?: string[];
}) => {
  let result = false;
  for (let newPre of prefix) {
    if (item.startsWith(newPre)) {
      result = true;
    }
  }
  for (let newEx of exception) {
    if (item === newEx) {
      result = false;
    }
  }
  return result;
};

export default useSearch;
