import { Fragment } from 'react';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { Box, Container, Flex, merge, Text } from 'theme-ui';
import { graphql } from 'gatsby';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import styled from '@emotion/styled';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import theme from './new-landing/_theme';
import List from '../../content/components/List';
import useSearch from '../../hooks/useSearch';
import SearchInput from '../../content/components/SearchInput/SearchInput';

const CustomInputWrapper = styled.form`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
  input {
    width: 100%;
    border-radius: 20px;
    padding: 24px 25px;
    border: 0px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    outline: none !important;
  }
  .icon {
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      fill: #aaa;
      font-weight: bold;
    }
  }
`;
const FlexWrapper = styled(Flex)`
  justify-content: space-between;
  .f1 {
    flex: 1;
    margin-right: 40px;
    position: relative;
  }
  .opacity:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .f2 {
    flex: 2;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    .f1 {
      flex: 1;
      margin-right: 0;
      width: 95%;
      margin: 0 auto;
    }
    .f2 {
      flex: 1;
      width: 95%;
      margin: 0 auto;
    }
  }
`;

const styles = {
  bottomTextContainer: {
    maxWidth: '700px',
    color: 'black',
    fontWeight: 'bold',
  },
};

const FAQPage = (props) => {
  const allBlockContent = props.data.allBlockContent;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const { onChange, value, newDataset, reset } = useSearch({
    dataset: content,
    prefix: ['faq'],
    exception: ['faq-categories'],
  });
  const categories = content['faq-categories'].description
    .detailedTexts as Array<object>;

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title='Faqs' />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container style={{ maxWidth: '1080px' }} mt={6}>
        <FlexWrapper sx={{ ml: -3, mr: -3, boxShadow: 'none' }}>
          <List
            outerClass={value ? 'f1 opacity' : 'f1'}
            header={{
              value: content['faq-categories'].title.text,
              key: 'faq-header',
            }}
            items={categories.map(({ value, key, link }: any) => {
              return {
                key: key,
                value: value[0],
                link: `#${link}`,
                icon: `${
                  content[`faq-${link}`]?.description.detailedTexts.length
                }`,
              };
            })}
            itemCommonCSS={{ fontWeight: 600 }}
          />
          <Box className='f2'>
            <SearchInput
              onChange={onChange}
              onReset={reset}
              placeholder={content['faq-categories']?.description?.textGroup[0]}
              value={value}
            />
            {value ? (
              <List
                items={
                  newDataset?.map(({ value, child }) => ({
                    value: value,
                    key: 'sakjdlkasd',
                    child: child,
                  })) || []
                }
                errorMessage={
                  content['faq-categories']?.description?.textGroup[1]
                }
                itemClassName='link'
                itemCommonCSS={{
                  color: '#444 !important',
                  fontWeight: 600,
                }}
                outerStyle={{ marginBottom: '2rem' }}
              />
            ) : (
              categories.map(({ link, value, key }: any) => (
                <Fragment>
                  <List
                    id={link}
                    header={{
                      value: value,
                      key: `${key}-items`,
                    }}
                    items={
                      content[`faq-${link}`]?.description.detailedTexts.map(
                        ({ value, child }) => ({
                          value: value,
                          key: 'sakjdlkasd',
                          child: child,
                        })
                      ) || []
                    }
                    itemClassName='link'
                    itemCommonCSS={{
                      color: '#444 !important',
                      fontWeight: 600,
                    }}
                    outerStyle={{ marginBottom: '2rem' }}
                  />
                </Fragment>
              ))
            )}
          </Box>
        </FlexWrapper>
        {content['faq-categories']?.text_1 && (
          <Container sx={styles.bottomTextContainer}>
            <Divider space='3' />
            <Text variant='h5' mb='0'>
              {content['faq-categories'].text_1?.text}
            </Text>
            <Text variant='h5'>{content['faq-categories'].text_2.text}</Text>
          </Container>
        )}
        <Divider space='5' />
      </Container>
      <Footer content={content['footer']} />
    </Layout>
  );
};
export const query = graphql`
  query siteFaqBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: [
            "footer"
            "header"
            "faq-categories"
            "faq-admin-items"
            "faq-features-items"
            "faq-general-information-items"
            "faq-joiner-items"
            "faq-services-and-security-items"
            "faq-wallet-items"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default FAQPage;
